/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/12/2021.
 */
import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Layout } from './components/display/Layout'

import { SpinnerFullHeight } from './components/spinner/SpinnerFullHeight'
import { ProjectList } from './pages/project/ProjectList'
import { ClientsPage } from './pages/clients/ClientsPage'
import { ProjectPage } from './pages/project/ProjectPage'

console.log('Main > ')
export const Main = () => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<Layout />}>
          <Route index element={<Navigate replace to="/projects" />} />

          <Route
            path="projects/*"
            element={
              <Suspense fallback={<SpinnerFullHeight />}>
                <ProjectList />
              </Suspense>
            }
          ></Route>
          <Route
            path="project/:projectId/*"
            element={
              <>
                <Suspense fallback={<SpinnerFullHeight />}>
                  <ProjectPage />
                </Suspense>
              </>
            }
          ></Route>
          <Route
            path="clients/*"
            element={
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <ClientsPage />
              </Suspense>
            }
          ></Route>
        </Route>

        <Route path={'*'} element={<Navigate replace to="/projects" />} />
      </Routes>
    </>
  )
}
