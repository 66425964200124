/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30/07/2023.
 */
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useMeWebhook } from '../../hooks/useWebhooks'
import { Button } from 'antd'

export const ProjectWebhookList = () => {
  const { projectId } = useParams() as { projectId: string }

  const { data } = useMeWebhook(projectId)

  return (
    <div className="row pb-4" style={{}}>
      {data && data.length > 0 ? (
        data.map((item, index) => {
          return (
            <div
              key={index}
              className="col-12 d-flex align-items-center border bg-white mb-2"
              style={{ boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.05)' }}
            >
              <div className="ps-2 my-3 " style={{ width: '33%' }}>
                <p className="p-mini opacity-50 mb-0">Title</p>
                <p className="mb-1 text-black text-truncate">{item.title}</p>
              </div>

              <div className="ps-2 my-3 " style={{ width: '33%' }}>
                <p className="p-mini opacity-75 mb-0">Url</p>
                <p className="mb-1 text-black text text-truncate">{item.url}</p>
              </div>

              <div className="d-flex justify-content-end " style={{ width: '33%' }}>
                <Link to={'webhook/' + item._id}>
                  <Button>Edit</Button>
                </Link>
              </div>
            </div>
          )
        })
      ) : (
        <div className="mt-0">
          <p className="opacity-50">No webhooks has been added</p>
        </div>
      )}
    </div>
  )
}
