import { FormTextField } from './FormTextField'
import { FormDropDown } from './FormDropDown'
import FormSwitch from './FormSwitch'
import { FormNumber } from './FormNumber'
import { FormSliderRange } from './FormSliderRange'
import { FormSlider } from './FormSlider'
import { FormSelectMultiple } from './FormSelectMultiple'
import { FormTime } from './FormTime'
import { CRUD_VIEW_TYPE } from '../../Types'
import { FormDate } from './FormDate'
import { FormUpload } from './FormUpload'
import { FormColorPicker } from './FormColorPicker'
import React from 'react'
import { FormTextArea } from './FormTextArea'

export const getFormType = (
  formConfigObj: any,
  index: number,
  formHook: any,
  viewType: CRUD_VIEW_TYPE = CRUD_VIEW_TYPE.CREATE
) => {
  const type = formConfigObj.type

  const copy = { ...formConfigObj }

  const definedViewType = viewType ? viewType : CRUD_VIEW_TYPE.CREATE

  switch (type) {
    case 'SECTION':
      return (
        <div key={index} className="col-12 mb-3 mt-5">
          <h5 className="fw-bold ">{copy.label}</h5>
          <hr className="mb-0" />
        </div>
      )

    case 'DIVIDER':
      return (
        <div key={index} className="col-12 mb-2 mt-5">
          <p className="fw-bold opacity-75">{copy.label}</p>
        </div>
      )

    case 'TEXT':
      return <FormTextField key={index} viewType={definedViewType} data={copy} formHook={formHook}></FormTextField>

    case 'TEXTAREA':
      return <FormTextArea key={index} viewType={definedViewType} data={copy} formHook={formHook}></FormTextArea>

    case 'DROPDOWN':
      return <FormDropDown key={index} data={copy} formHook={formHook}></FormDropDown>

    case 'SWITCH':
      return <FormSwitch key={index} data={copy} formHook={formHook}></FormSwitch>

    case 'NUMBER':
      return <FormNumber key={index} data={copy} formHook={formHook}></FormNumber>

    case 'SLIDER_RANGE':
      return <FormSliderRange key={index} data={copy} formHook={formHook}></FormSliderRange>

    case 'SLIDER':
      return <FormSlider key={index} data={copy} formHook={formHook}></FormSlider>

    case 'SELECT_MULTIPLE':
      return <FormSelectMultiple key={index} data={copy} formHook={formHook}></FormSelectMultiple>

    case 'TIME':
      return <FormTime key={index} data={copy} formHook={formHook}></FormTime>

    case 'DATE':
      return <FormDate key={index} data={copy} formHook={formHook}></FormDate>

    case 'COLOR':
      return (
        <FormColorPicker
          key={index}
          viewType={definedViewType}
          data={formConfigObj}
          formHook={formHook}
        ></FormColorPicker>
      )

    case 'FILE':
      return <FormUpload key={index} data={formConfigObj} formHook={formHook}></FormUpload>

    default:
      return (
        <div key={Math.random() * 10} className="">
          WHAT
        </div>
      ) //<div key={index}>{type}</div>
  }
}
