/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 24/10/2023.
 */
import { Parser } from '@json2csv/plainjs'
import React, { useState } from 'react'
import { useParticipants } from '../../hooks/useParticipants'
import { useParams } from 'react-router-dom'
import { Button, Popconfirm } from 'antd'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../config/endpoints'
import { openNotification } from '../../components/notificationToast'

export const ParticipantsPage = () => {
  const { projectId } = useParams() as { projectId: string }
  const { data, mutate } = useParticipants(projectId)

  const [csvLoading, setCsvLoading] = useState<boolean>(false)

  const confirmDeletion = (userId: string) => {
    const endpoint = ENDPOINTS.ADMIN.PARTICIPANTS.ACTION.deleteUser(projectId)
    post(endpoint, { projectId: projectId, userId: userId }, 'DELETE')
      .then((result) => {
        openNotification('Participant deleted', '', 'happy')
        mutate()
        return result
      })
      .catch((error) => {
        console.log('ParticipantsPage > error = ', error)
        return alert(error)
      })
  }

  const downloadCsv = () => {
    if (data.length === 0) {
      return
    }

    setCsvLoading(true)
    try {
      const opts = {}
      const parser = new Parser(opts)
      const csv = parser.parse(data)

      const url = window.URL.createObjectURL(new Blob([csv]))
      const link = document.createElement('a')
      link.href = url

      const today = new Date()
      const yyyy = today.getFullYear()
      let mm = today.getMonth() + 1 // Months start at 0!
      let dd = today.getDate()

      const formattedToday = dd + '/' + mm + '/' + yyyy

      const flleName = 'participants-' + formattedToday + '.csv'
      link.setAttribute('download', flleName)

      // Append to html link element page
      document.body.appendChild(link)

      // Start download
      link.click()

      // Clean up and remove the link
      link.parentNode?.removeChild(link)
      setCsvLoading(false)
    } catch (err) {
      setCsvLoading(false)
      console.error(err)
      alert(err)
    }
  }

  return (
    <div className="container">
      <div className="row" style={{}}>
        <div className="col-12 my-6 d-flex justify-content-between align-items-center">
          <div className="">
            <h3>
              Participants <span className="opacity-50">({data.length})</span>
            </h3>
          </div>

          <div className="d-flex justify-content-end ">
            <Button
              onClick={downloadCsv}
              disabled={data.length === 0 ? true : false}
              className="me-2"
              type="primary"
              loading={csvLoading}
            >
              Download CSV
            </Button>
          </div>
        </div>
      </div>
      <div className="row" style={{}}>
        {data.map((user, index) => {
          return (
            <div key={index} className="col-12  mb-2 ">
              <div className="bg-white p-4 d-flex">
                <div className="w-25">
                  <p className="p-small opacity-75">Name</p>
                  <p>
                    {user?.name} {user?.lastname}
                  </p>
                </div>

                <div className="w-25">
                  <p className="p-small opacity-75">email</p>
                  <p>{user?.email}</p>
                </div>
                <div className="w-25">
                  <p className="p-small opacity-75">answer</p>
                  <p>{user?.answer}</p>
                </div>
                <div className="w-25 d-flex justify-content-between align-items-center">
                  <div className="">
                    <p className="p-small opacity-75">Created</p>
                    <p>{user?.createdAt.split('T')[0]}</p>
                  </div>
                  <Popconfirm
                    title="Delete the task"
                    description="Are you sure to delete this task?"
                    onConfirm={() => {
                      confirmDeletion(user._id)
                    }}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="default">Delete</Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
