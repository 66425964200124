export type User = {
  _id: string
}

export type user = {
  name: string
}

export enum CRUD_VIEW_TYPE {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  VIEW = 'VIEW',
}

export type SubjectType = {
  _id: string
  name: string
}
