/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/05/2023.
 */
import React from 'react'

type Props = {
  title?: React.ReactElement | string
  body?: React.ReactElement | string
  children?: React.ReactElement | string | null
  backTo?: string
}

export const BlockHeader = ({ title = 'Page title', body = '', children, backTo }: Props) => {
  return (
    <div className="row">
      <div className="col-12 my-5 pe-3 d-flex justify-content-between align-items-center">
        <div className="">
          <h5 className="">{title}</h5>
          <p>{body}</p>
        </div>
        {children}
      </div>
    </div>
  )
}
