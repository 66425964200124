/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30/07/2023.
 */
import React from 'react'
import { getFormType } from '../../components/form/formFactory'
import { Button } from 'antd'
import { populateConfig } from '../../util/populateConfig'
import { getProjectUser } from './projectClientConfig'
import { useForm } from 'react-hook-form'
import { getReq, post } from '../../service/API'
import { openNotification } from '../../components/notificationToast'
import { useParams } from 'react-router-dom'
import { CRUD_VIEW_TYPE } from '../../Types'
import { omit } from 'lodash'
import { ENDPOINTS } from '../../config/endpoints'
import { useById } from '../../hooks/useById'
import dayjs from 'dayjs'

type Props = {
  viewType: CRUD_VIEW_TYPE
  mutateFunc?: () => void
  onClose?: () => void
}

/*
 * This component is used to create client only
 * Each client is attached to a project inside the project file
 */

export const ProjectClientCRUD = ({ viewType, mutateFunc, onClose }: Props) => {
  const { clientId } = useParams() as { clientId: string }

  console.log('ProjectClientCRUD > viewType = ', viewType)
  const p = ENDPOINTS.ADMIN.CLIENTS.get + '/'
  const { data } = useById(p, clientId)

  console.log('ProjectClientCRUD > data = ', data)

  const formHook = useForm()
  const { handleSubmit } = formHook

  const configObj = populateConfig(getProjectUser(), data)

  const onSubmit = (formData, e) => {
    e.preventDefault()

    const updatedData = { /*...data,*/ ...formData }

    const method = viewType === CRUD_VIEW_TYPE.UPDATE ? 'PUT' : 'POST'
    const sendData = viewType === CRUD_VIEW_TYPE.UPDATE ? updatedData : formData
    const omittedObj = omit(sendData, ['inviteSent'])
    const updatedSendData = { ...omittedObj }

    return post(ENDPOINTS.ADMIN.CLIENTS.get, updatedSendData, method)
      .then((result) => {
        console.log('ProjectClientCRUD > result = ', result)
        openNotification('Client created', result.data ? result.data.message : 'Client has been created', 'happy')

        if (mutateFunc) {
          mutateFunc()
        }

        if (onClose) {
          onClose()
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        //setErrorState(error)
      })
  }

  const onError = (err, k) => {
    console.error('ProjectView > err = ', err)
    console.log('ProjectView > k = ', k)
  }
  const sendInvite = () => {
    const p = ENDPOINTS.ADMIN.CLIENTS.invite(clientId)
    console.log('ProjectClientCRUD > p = ', p)
    getReq(p)
  }

  const inviteDate = data?.inviteSent ? dayjs(data.inviteSent).format('DD/MM/YYYY  - hh:mm ') : '' // '25/01/2019'

  return (
    <div className="container-fluid px-0">
      <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
        {configObj.map((item, index) => {
          const comp = getFormType(item, index, formHook)
          return comp
        })}

        {viewType === CRUD_VIEW_TYPE.CREATE ? (
          <div className="col-12 mt-2 mb-4 ">
            <Button className="w-100" onClick={handleSubmit(onSubmit, onError)} type="primary">
              Save
            </Button>
          </div>
        ) : null}
      </form>
      <div className="row" style={{}}>
        <div className="col-12">
          <hr />
        </div>

        <div className="col-12">
          <h6>Invite user to platform</h6>
          <p>
            Before the user can access the platform, they must have recieved an iinvite. The invite contains a temporary
            password the clint can use to login
          </p>
          <Button className="w-100 mt-4" onClick={sendInvite} type="primary">
            Send invite
          </Button>

          {data && data.inviteSent ? (
            <div className="mt-2">
              <p>Invite sent: {inviteDate}</p>
            </div>
          ) : (
            <div className="mt-2">
              <p>Invite not sent</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
