/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 23/07/2023.
 */
import React, { useEffect, useState } from 'react'
import { FormDataType } from './FormTypes'
import { UseFormReturn } from 'react-hook-form'

import { Alert, Button, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import { COLOR_PLACEHOLDER, S3_BUCKET_URL } from '../../constants'
import { useParams } from 'react-router-dom'
import { postFileUpload } from '../../service/API'
import { ENDPOINTS } from '../../config/endpoints'
import Spinner from '../spinner/Spinner'

type Props = {
  data: FormDataType
  formHook: UseFormReturn
}

/*const props: UploadProps = {
  //action: API_URL '/api/projects',
  data: { denis: 'test' },
  beforeUpload: (file) => {
    const isPNG = file.type === 'image/png'
    if (!isPNG) {
      message.error(`${file.name} is not a png file`)
    }
    return isPNG || Upload.LIST_IGNORE
  },
  onChange: (info) => {
    console.log('FormUpload > chane info.fileList = ', info.fileList)
  },
}*/

export const FormUpload = ({ data, formHook }: Props) => {
  const className = data.className || 'col-12 w-100 '

  const [blobImg, setBlob] = useState('')
  const [state, setState] = useState<any>(null)

  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const { projectId } = useParams() as { projectId: string }

  useEffect(() => {
    if (!state) {
      return
    }

    const reader = new FileReader()
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
      const blob = 'url(' + reader.result + ')'
      setBlob(blob)

      reader.onabort = null
      reader.onerror = null
      reader.onload = null
    }

    reader.readAsDataURL(state as any)
  }, [state])

  const customRequest = (antFormData) => {
    const formData = new FormData()
    formData.append('file', antFormData.file)
    formData.append('type', data.id)

    const p = ENDPOINTS.ADMIN.PROJECTS.imageUpload(projectId)

    setLoading(true)

    return (
      postFileUpload(p, formData)
        //return postFileUpload(p, { ok: true })
        .then((result) => {
          antFormData.onSuccess(result)
          setError('')
          setLoading(false)
          setState(antFormData.file)
          return true
        })
        .catch((error) => {
          setLoading(false)
          console.log('FormUpload > error ++ = ', error)
          setError(error.message)
          //return Promise.reject(error)
        })
    )
  }

  const pathBgImage = `url(${S3_BUCKET_URL + '/' + data.value})`
  const bgImage = blobImg ? blobImg : pathBgImage
  const hasImage = state || bgImage ? true : false

  return (
    <div className={` ${className} py-2`}>
      <div className="row" style={{}}>
        <div className="col-12 my-2">
          <p className="p-small ">{data.placeholder}</p>
        </div>

        <div className="col-12 mb-2">
          {hasImage && !loading ? (
            <div
              className="ratio ratio-16x9 back-image-cover"
              style={{ backgroundImage: bgImage, boxShadow: '1px 1px 6px 2px rgba(0,0,0,0.08)' }}
            ></div>
          ) : (
            <div className="ratio ratio-16x9 position-relative ">
              {loading ? (
                <div className="position-absolute w-100 h-100" style={{ top: 0, left: 0, zIndex: 10 }}>
                  <div className="d-flex w-100 h-100 justify-content-center align-items-center pb-4">
                    <div className="">
                      <Spinner style={{ marginLeft: 20 }}></Spinner>
                      <p className="p-small mt-4">Uploading</p>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="rounded-2" style={{ background: COLOR_PLACEHOLDER }}></div>
            </div>
          )}
          {error ? (
            <div className="col-12 py-4">
              <Alert message="Something went wrong!" description={error} type="error" />
            </div>
          ) : null}
        </div>
        <div className="col-12 ">
          <Upload customRequest={customRequest} fileList={[]}>
            <Button className="mt-1" type="primary" icon={<UploadOutlined />}>
              {data.label}
            </Button>
          </Upload>
        </div>
      </div>
    </div>
  )
}
