/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30/07/2023.
 */
import React from 'react'
import { useParams } from 'react-router-dom'
import { useById } from '../../hooks/useById'
import { ENDPOINTS } from '../../config/endpoints'

export const ProjectClients = () => {
  const { projectId } = useParams() as { projectId: string }

  const endpoint = ENDPOINTS.ADMIN.CLIENTS.byProjectId + '/'
  const { data } = useById(endpoint, projectId)

  return (
    <div className="row pb-3" style={{}}>
      {data.map((item, index) => {
        return (
          <div
            key={index}
            className="col-12 d-flex align-items-center border bg-white"
            style={{ boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.05)' }}
          >
            <div className="ps-2 my-3 " style={{ width: '33%' }}>
              <p className="p-mini opacity-50 ">Email</p>
              <p className="mb-1 text-black text-truncate">{item.email}</p>
            </div>

            <div className="ps-2 my-3 " style={{ width: '33%' }}>
              <p className="p-mini opacity-75 ">Name</p>
              <p className="mb-1 text-black text text-truncate">{item.name}</p>
            </div>

            <div className="d-flex justify-content-end " style={{ width: '33%' }}></div>
          </div>
        )
      })}
    </div>
  )
}
