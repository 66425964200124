export const ACCEPTED_FILE_TYPES = ['image/png', 'image/jpg']

export const BASE_URL = process.env.REACT_APP_BASE_URL
export const API_URL = BASE_URL + '/api'

export const DEBUG = process.env.REACT_APP_DEBUG === 'true' ? true : false
export const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL!.toString()

export const REACT_APP_ENV = process.env.REACT_APP_ENV
export const COLOR_DANGER = '#dc3545'
export const COLOR_PLACEHOLDER = '#eaeaea'
export const COLOR_PRIMARY = '#0500FF' //'#007bff'
export const COLOR_NOT_SELECTED = '#bbbbbb'
