/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28/03/2023.
 */
import { TimePicker } from 'antd'
import React, { useEffect } from 'react'
import { FormLabel } from './FormLabel'

const format = 'HH:mm'
export const FormTime = ({ data, formHook }) => {
  const {
    register,

    formState: { errors },
  } = formHook

  const errorState = errors[data.id]

  const errorMessage = errorState && errorState.message ? errorState.message : data.errorMsg

  //const formValue = getValues(data.id)
  //  const currentValue = !formValue ? data.value : formValue

  useEffect(() => {
    register(data.id, data.validation)
  }, [register, data.id, data.validation])

  const handleChange = (value, time) => {
    console.log(' FormTime > value = ', value)
    console.log(' FormTime > time = ', time)
    //setValue(data.id, value)
    //setState(value)
  }

  return (
    <div className={` ${data.className} py-2`}>
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>
      <TimePicker
        className="w-100"
        placeholder={data.placeholder}
        format={format}
        minuteStep={15}
        showNow={false}
        onChange={handleChange}
      />
      {errorState ? <div className="p-small text-danger">{errorMessage}</div> : null}
    </div>
  )
}
