export type PageType = {
  title: string
  to: string
  path: string
  visible: boolean
  gotoPage: (...rest) => string
}

export const PROJECT_CONFIG: PageType = {
  title: 'Project configuration',
  path: `/`,
  to: ``,
  visible: true,
  gotoPage: (id) => {
    return `/project/${id}`
  },
}

export const PARTICIPANTS_PAGE: PageType = {
  title: 'Participants',
  path: `/participants`,
  to: `participants`,
  visible: true,
  gotoPage: (id) => {
    return `/project/${id}/participants`
  },
}

export const OVERVIEW_PAGE: PageType = {
  title: 'Overview',
  path: `overview`,
  to: `/overview`,
  visible: true,
  gotoPage: (id) => {
    return `/project/${id}/overview`
  },
}

export const PROJECTS_OVERVIEW_PAGE: PageType = {
  title: 'Projects',
  path: `/projects`,
  to: `/projects`,
  visible: true,
  gotoPage: (id) => {
    return ``
  },
}

export const CLIENTS_PAGE: PageType = {
  title: 'Clients',
  path: '/clients',
  to: '/clients',
  visible: true,
  gotoPage: (id) => {
    return ``
  },
}

export const PROJECT_LIST_PAGE: PageType = {
  title: 'Projects',
  path: `/project`,
  to: `/project`,
  visible: true,
  gotoPage: (id) => {
    return `/`
  },
}

export const ROUTES = [OVERVIEW_PAGE, PROJECTS_OVERVIEW_PAGE, CLIENTS_PAGE]
export const HEADER_ROUTES = [PROJECT_CONFIG, PARTICIPANTS_PAGE]
