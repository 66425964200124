import { FormLabel } from './FormLabel'
import { Select } from 'antd'

import { useEffect } from 'react'

const { Option } = Select

export const FormSelectMultiple = ({ data, formHook }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = formHook

  const errorState = errors[data.id]

  useEffect(() => {
    register(data.id, data.validation)
  }, [data.id, data.validation, register])

  const items = data.options.map((item, index) => {
    return (
      <Option key={index} value={item.value} label={item.label} title={item.title}>
        <div>
          <span role="img" aria-label={item.label}>
            {item.symbol}
          </span>
          {item.label}
        </div>
      </Option>
    )
  })

  const handleChange = (value: any) => {
    setValue(data.id, value)
  }
  //------------- SOLUTION FOR [title: ..., iso: ...]
  // const handleChange = (valueArray: any) => {
  //   const languageArray: any = []
  //   if (valueArray != null) {
  //     valueArray.map((item) => {
  //       switch (item) {
  //         case 'es':
  //           languageArray.push({ title: 'Spanish', iso: item })
  //           setValue(data.id, languageArray)
  //           break
  //         case 'en':
  //           languageArray.push({ title: 'English', iso: item })
  //           setValue(data.id, languageArray)
  //           break
  //         case 'jp':
  //           languageArray.push({ title: 'Japanese', iso: item })
  //           setValue(data.id, languageArray)
  //           break
  //         case 'cn':
  //           languageArray.push({ title: 'Chinese', iso: item })
  //           setValue(data.id, languageArray)
  //           break
  //         case 'kr':
  //           languageArray.push({ title: 'Korean', iso: item })
  //           setValue(data.id, languageArray)
  //           break
  //         case 'dk':
  //           languageArray.push({ title: 'Danish', iso: item })
  //           setValue(data.id, languageArray)
  //           break
  //       }
  //     })
  //   }
  // }

  return (
    <div className="w-100 py-1">
      <FormLabel label={data.label} />
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder="Select language"
        onChange={handleChange}
        optionLabelProp="label"
        defaultValue={formHook.getValues('languages') ? formHook.getValues('languages') : []}
      >
        {items}
      </Select>
      {errorState ? <div className="p-small text-danger">{data.errorMsg}</div> : null}
    </div>
  )
}
