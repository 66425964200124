import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../config/endpoints'

/**
 * Get all projects for the current user
 */
export const useMeWebhook = (projectId: string) => {
  const p = projectId ? ENDPOINTS.ADMIN.WEBHOOK.get(projectId) : null

  const { data, error, mutate } = useSWR(p, getReq, { suspense: true })

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
