/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 09/03/2023.
 */
import React, { useState } from 'react'

const DEBUG_CLASS = 'debugger-grid'
export const GridDebugger = () => {
  const [state, setState] = useState<number>(0)

  const toggle = () => {
    setState((prevState) => {
      const update = prevState === 2 ? 0 : prevState + 1

      if (update === 2) {
        document.body.classList.add(DEBUG_CLASS)
      } else {
        document.body.classList.remove(DEBUG_CLASS)
      }

      return update
    })
  }

  return (
    <>
      <div
        className="bg-black ps-3 noselect pe-2 py-1 text-white p-mini pointer position-absolute"
        onClick={toggle}
        style={{ fontSize: 10, zIndex: 1001 }}
      >
        {state > 0 ? 'debug' : '-'}
      </div>
      {state === 1 && (
        <div className="position-absolute h-100 w-100 mx-auto" style={{ top: 0, left: 0 }}>
          <div className="container h-100 ">
            <div className="row h-100 " style={{}}>
              <ColOne></ColOne>
              <ColOne></ColOne>
              <ColOne></ColOne>
              <ColOne></ColOne>
              <ColOne></ColOne>
              <ColOne></ColOne>
              <ColOne></ColOne>
              <ColOne></ColOne>
              <ColOne></ColOne>
              <ColOne></ColOne>
              <ColOne></ColOne>
              <ColOne></ColOne>
            </div>
          </div>
        </div>
      )}

      {/*{state === 2 && <DebugGrid></DebugGrid>}*/}
    </>
  )
}

const ColOne = () => {
  return (
    <div className="col-1" style={{ background: 'rgba(0,0,0,0.05)' }}>
      <div className="h-100 w-100" style={{ background: 'rgba(0,0,0, 0.1)' }}></div>
    </div>
  )
}
