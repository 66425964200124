/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28/07/2023.
 */
import React from 'react'

type Props = { project: any }

//const details = ['title', 'client', 'market', 'url', 'start', 'end']
const details = ['client', 'market', 'url', 'start', 'end']

export const ProjectDetails = ({ project }: Props) => {
  return (
    <>
      <div className="row mt-2 mb-2" style={{}}>
        <div className="col-8 mb-4 d-flex flex-wrap">
          {details.map((key, index) => {
            return <Item key={index} title={details[index]} value={project[key]}></Item>
          })}
        </div>
      </div>
    </>
  )
}
const Item = ({ title, value }) => {
  const theValue = title === 'start' || title === 'end' ? value.toString().split('T')[0] : value
  return (
    <div className="me-3 px-3 py-2 rounded-3" style={{ background: '#f3f3f3' }}>
      <p className="p-small opacity-75">{title}</p>
      <p className="fw-bold p-small text-truncate" style={{ maxWidth: 220 }}>
        {theValue}
      </p>
    </div>
  )
}
