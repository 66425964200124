export const LOGIN = { title: 'Login', to: `/`, base: '/', visible: false }
export const LOGIN_NEW_PASSWORD = {
  title: 'New-password',
  path: `/new-password`,
  to: '/login/new-password',
  visible: false,
}
export const LOGIN_FORGOT_PASSWORD_SUBMIT = {
  title: 'submit-new-password',
  to: `/login/submit-new-password`,
  path: '/submit-new-password',
  visible: false,
}
export const LOGIN_FORGOT_PASSWORD = {
  title: 'forgot password',
  path: 'forgot-password',
  to: `/login/forgot-password`,
  visible: false,
}

export const LOGIN_MFA_CODE = { title: 'mfa', path: '/mfa', to: `/login/mfa`, visible: false }
