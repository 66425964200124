import { getReq } from '../service/API'
import useSWR from 'swr'

export const useById = (endpoint: string, id: string) => {
  const p = endpoint && id ? endpoint + id : null

  const { data, error, mutate } = useSWR(p, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
