import { DEBUG } from '../constants'
import { Auth } from 'aws-amplify'

export const getReq = async (p: any): Promise<any> => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken()
  //const token = (await Auth.currentSession()).getAccessToken().getJwtToken()

  return fetch(p, {
    method: 'GET',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then((res) => {
      const contentType = res?.headers?.get('Content-Type')
      const isJson = contentType && contentType.indexOf('application/json') > -1

      if (DEBUG) {
        console.log(' API > DEBUG res.status = ', res.status, p)
      }

      if (res.status === 404) {
        console.log(' API > res = ', res)
        const url = res.url || ''
        return Promise.reject(getErrorResponse(res, 404, 'Could not find endpoint ' + url))
      }

      if (res.status === 401) {
        console.log(' API GET > 401 401 401 401401 = ')
        console.log(' API > res = ', res)
        return Promise.reject(getErrorResponse(res, 401, 'NO AUTHHHH '))
      }

      if (!isJson) {
        return res.text()
      }

      return res.json()
    })
    .then((res) => {
      if (res.error || res.result === false) {
        console.log(' API > ALLLSEEEE = ')
        return Promise.reject(res)
      }

      return res
    })

    .catch((err) => {
      console.log(' API > err = ', err)
      console.log(' API > iseeeee = ')
      return Promise.reject(err)
    })
}

export const post = async (p: string, data: any, method: string = 'POST') => {
  //const token = getToken()

  const token = (await Auth.currentSession()).getIdToken().getJwtToken()

  return fetch(p, {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (DEBUG) {
        console.log(' API > res.status = ', res.status, p)
      }

      if (res.status === 401) {
        console.log(' API POST > 401 401 401 401401 = ')
        return Promise.reject(res)
        //return updateAccessToken(p, 'POST', data)
      }

      if (res.status !== 200) {
        return Promise.reject(res)
      }

      return res.json()
    })
    .then((res) => {
      if (res.type === 'VALIDATION') {
        console.log(' API > res.message = ', res.message)
      }

      if (res.result === false) {
        return Promise.reject(res)
      }
      return res
    })

    .catch(async (err) => {
      //const parsedErr = await err.json()

      return Promise.reject(err)
    })
}

export const postFileUpload = async (p: string, data: any, method: string = 'POST') => {
  //const token = getToken()

  const token = (await Auth.currentSession()).getIdToken().getJwtToken()

  return fetch(p, {
    method: method,
    headers: {
      Authorization: 'Bearer ' + token,
      //'Content-Type': 'multipart/form-data',
    },
    body: data,
  })
    .then((res) => {
      if (DEBUG) {
        console.log(' API > res.status = ', res.status, p)
      }
      if (res.status === 401) {
        console.log(' API POST > 401 401 401 401401 = ')
        return Promise.reject(res)
        //return updateAccessToken(p, 'POST', data)
      }
      if (res.status === 422) {
        console.log(' API POST > 422 422 upload failed = ')
        return Promise.reject(res)
        //return updateAccessToken(p, 'POST', data)
      }

      return res.json()
    })
    .then((res) => {
      if (res.result === false) {
        return Promise.reject(res)
      }
      return res
    })
    .catch((err) => {
      //const contentType = err?.headers?.get('Content-Type')
      //const isJson = contentType && contentType.indexOf('application/json') > -1

      //
      // The error is already parsed to json
      // can be sent to what ever error handler
      //
      if (err?.result === false) {
        console.log('API > stopp = ')
        return Promise.reject(err)
      }

      /*if (isJson) {
        return err.json().then((errResult) => {
          return Promise.reject(errResult)
        })
      } else {
        return err.text().then((errResult) => {
          console.log('API > errResult = ', errResult)
          return Promise.reject(errResult)
        })
      }*/

      return Promise.reject(err)
    })
}

export const put = async (p: string, data: any) => {
  return post(p, data, 'PUT')
}

export const destroy = async (p: string, data?: any) => {
  return post(p, data, 'DELETE')
}

function getErrorResponse(err, status: number, msg) {
  return {
    result: false,
    status: status,
    err: err,
    message: msg,
  }
}
