/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30/07/2023.
 */
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ENDPOINTS } from '../../config/endpoints'
import { populateConfig } from '../../util/populateConfig'

import { useForm } from 'react-hook-form'
import { post } from '../../service/API'
import { openNotification } from '../../components/notificationToast'
import { getFormType } from '../../components/form/formFactory'
import Alert from 'antd/lib/alert'
import { Button } from 'antd'
import { FormLabel } from '../../components/form/FormLabel'
import { getWebhookConfig } from './webhookConfig'
import { useWebhookById } from '../../hooks/useWebhookById'
import { useSWRConfig } from 'swr'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: 'CREATE' | 'UPDATE' | 'VIEW'
}

//export const ProjectCRUD = ({ onClose, closeDrawer, viewType = 'CREATE', mutateProjectList }: Props) => {

export const ProjectWebhookDrawer = ({ onClose, closeDrawer, viewType }: Props) => {
  const { projectId, webhookId } = useParams() as { projectId: string; webhookId: string }

  const { data, mutate } = useWebhookById(projectId, webhookId)

  const configObj = viewType === 'UPDATE' ? populateConfig(getWebhookConfig(), data) : getWebhookConfig()

  const formHook = useForm()

  const { handleSubmit } = formHook
  const [errorState, setErrorState] = useState<any>()

  const { mutate: mutateHookList } = useSWRConfig()

  const onSubmit = (formData, e) => {
    e.preventDefault()

    //const createData = { ...formData }

    const updatedData = { ...data, ...formData, projectId: projectId }

    const method = viewType === 'UPDATE' ? 'PUT' : 'POST'
    //const sendData = viewType === 'UPDATE' ? updatedData : createData

    //const updatedSendData = { ...sendData, projectId: projectId }

    //
    // notice that we are using the same endpoint for both create and update
    // The backend shares the same endpoint for both create and update of the project details and the text for pages
    //
    const path = ENDPOINTS.ADMIN.WEBHOOK.ACTION.crud(projectId)

    return post(path, updatedData, method)
      .then((result) => {
        const headline = viewType === 'CREATE' ? 'Webhook created' : 'Webhook updated'
        const description = viewType === 'CREATE' ? '' : ''
        openNotification(headline, description)
        mutate()

        if (onClose) {
          onClose()
        }

        const p = ENDPOINTS.ADMIN.WEBHOOK.get(projectId)
        mutateHookList(p)

        if (closeDrawer) {
          closeDrawer(false)
          //setAddSiteDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error?.error)
        setErrorState(error)
      })
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  const navigateBack = () => {
    if (onClose) {
      console.log('-- > navigateBack = ')
      onClose()
    }
  }

  const deleteClick = () => {
    const path = ENDPOINTS.ADMIN.WEBHOOK.ACTION.crud(projectId)

    return post(path, { webhookId: webhookId }, 'DELETE')
      .then((result) => {
        openNotification('Webhook has been deleted', '')
        mutate()

        const p = ENDPOINTS.ADMIN.WEBHOOK.get(projectId)
        mutateHookList(p)

        if (onClose) {
          onClose()
        }

        if (closeDrawer) {
          closeDrawer(false)
          //setAddSiteDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error?.error)
        setErrorState(error)
      })
  }

  const testHook = () => {
    const path = ENDPOINTS.ADMIN.WEBHOOK.ACTION.testHook(projectId)

    return post(path, { projectId: projectId })
      .then((result) => {
        console.log('ProjectWebhookDrawer > result = ', result)
        const isSuccess = result?.data === true ? 'Result was a success' : 'It failed'
        openNotification('Webhook tested', isSuccess)
      })
      .catch((error) => {
        alert(JSON.stringify(error.message))
      })
  }

  return (
    <>
      {viewType === 'CREATE' || viewType === 'UPDATE' ? (
        <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
          {configObj.map((item, index) => {
            const comp = getFormType(item, index, formHook)
            return comp
          })}

          <div className="col-12 mt-3">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>

          <div className="col-12 d-flex mt-1">
            {viewType === 'UPDATE' ? (
              <Button onClick={deleteClick} className="me-2" type="primary" danger={true}>
                Delete
              </Button>
            ) : null}

            <div className="d-flex justify-content-end w-100">
              <Button className="me-2" onClick={navigateBack}>
                Cancel
              </Button>
              <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
                {viewType === 'CREATE' ? 'Create Webhook' : 'Save changes'}
              </Button>
            </div>
          </div>
          {viewType === 'UPDATE' ? (
            <>
              <div className="col-12 mt-4">
                <hr />
              </div>

              <div className="col-12 mt-2 d-flex justify-content-between">
                <div className="">
                  <h6 className="mb-1">Test webhook</h6>
                  <p>Send fake data to the webhook</p>
                </div>
                <Button className="mt-3" onClick={testHook}>
                  Test webhook
                </Button>
              </div>
            </>
          ) : null}
        </form>
      ) : (
        <div className="">
          <div className="col-12 mb-4">
            <div className="d-flex ">
              <FormLabel label="Name"></FormLabel>
              <p className="ms-2 p-small opacity-25 ">{data ? data._id : '-'}</p>
            </div>
            <h6 className="fw-bold">{data ? data.name : '-'}</h6>
          </div>

          <div className="col-12 mb-4">
            <FormLabel label="Email"></FormLabel>
            <h6 className="fw-bold">{data ? data.email : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Phone"></FormLabel>
            <h6 className="fw-bold">{data ? data.phone.countryCode + ' ' + data.phone.tel : '-'}</h6>
          </div>

          <div className="col-12 mb-4">
            <FormLabel label="Status"></FormLabel>
            <h6 className="fw-bold">{data ? data.status : '-'}</h6>
          </div>

          <div className="col-12 mb-4">{/*<SiteBarSiteList selectedSites={selectedSites}></SiteBarSiteList>*/}</div>

          <div className="col-12 d-flex justify-content-end mb-4">
            <Button className="me-2" onClick={navigateBack}>
              Cancel
            </Button>
            <Link to="edit">
              <Button type="primary">Edit Clinician</Button>
            </Link>
          </div>

          <div className="col-12">
            <hr />
          </div>
        </div>
      )}
    </>
  )
}
