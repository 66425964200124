export const getProjectUser = () => {
  return projectClientConfig
}

const projectClientConfig = [
  {
    id: 'name',
    type: 'TEXT',
    placeholder: 'Add user name',
    label: 'Name',
    value: '',
    validation: { required: true },
    errorMsg: 'Name is required',
  },
  {
    id: 'email',
    type: 'TEXT',
    placeholder: 'Add user email',
    label: 'Email',
    value: '',
    validation: { required: true },
    errorMsg: 'Email required',
    disabled: true,
  },
]
