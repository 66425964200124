import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../config/endpoints'

/**
 * Get all projects for the current user
 */
export const useMeProjects = () => {
  const { data, error, mutate } = useSWR(ENDPOINTS.PROJECTS.get, getReq, { suspense: true })

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
