/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/04/2023.
 */
import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import { Button } from 'antd'
import { useMeProjects } from '../../hooks/useMeProjects'
import { PageHeader } from '../../components/display/PageHeader'
import { DrawerContainer } from '../../components/drawer/DrawerContainer'
import { ProjectCRUD } from './ProjectCRUD'

//TODO: When you get props pass them, uncommented to avoid empty object pattern
//type Props = {}

export const ProjectList = () => {
  const { data, mutate } = useMeProjects()

  return (
    <>
      <Routes>
        <Route
          path="create"
          element={
            <>
              <DrawerContainer projectId="" title="Create project">
                <ProjectCRUD mutateProjectList={mutate}></ProjectCRUD>
              </DrawerContainer>
            </>
          }
        />
        <Route path="/:projectId" element={<h1>project id </h1>} />
      </Routes>

      <div className="container px-4 mt-4">
        <PageHeader title="Project list">
          <Link to="create">
            <Button>Create project +</Button>
          </Link>
        </PageHeader>
        <div className="row" style={{}}>
          {data.map((project, index) => {
            return (
              <div
                key={index}
                className="col-12 item-shadow mb-2 bg-white pt-5 pb-6 px-5 border d-flex align-items-center justify-content-between"
              >
                <div className="w-50">
                  <p className="p-mini fw-semibold text-muted">{project._id}</p>
                  <h5 className="fw-semibold mb-0">{project.title}</h5>
                </div>
                <div className="w-25">
                  <p className="p-mini fw-semibold text">CREATED</p>
                  <p className="mb-0">{project.createdAt.split('T')[0]}</p>
                </div>
                <div className="w-25 d-flex justify-content-end">
                  <Link to={'/project/' + project._id}>
                    <Button type="primary">View</Button>
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
