import { get } from 'lodash'
import { FormDataType } from '../components/form/FormTypes'

export const populateConfig = (config, data) => {
  const copy = config.concat([])

  const k = copy.map((item) => {
    const copy: FormDataType = { ...item }

    const value = get(data, copy.id)

    copy.value = value

    return copy
  })

  return k
}
