/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30/07/2023.
 */
import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import { DrawerContainer } from '../../components/drawer/DrawerContainer'
import { ProjectClientCRUD } from '../project/ProjectClientCRUD'
import { CRUD_VIEW_TYPE } from '../../Types'
import { Button } from 'antd'
import { PageHeader } from '../../components/display/PageHeader'
import { useClients } from '../../hooks/useClients'

export const ClientsPage = () => {
  const { data, mutate } = useClients()

  const mutateList = () => {
    mutate()
  }

  return (
    <>
      <Routes>
        {/*<Route
          path="edit-client"
          element={
            <>
              <DrawerContainer projectId={projectId} title="asd">
                <h1>edit details</h1>
              </DrawerContainer>
            </>
          }
        />*/}
        <Route
          path="add-user"
          element={
            <>
              <DrawerContainer projectId="" to="../" title="Add user">
                <ProjectClientCRUD viewType={CRUD_VIEW_TYPE.CREATE} mutateFunc={mutateList} />
              </DrawerContainer>
            </>
          }
        />
        <Route
          path="view/:clientId"
          element={
            <>
              <DrawerContainer projectId="" to="../" title="Add user">
                <ProjectClientCRUD viewType={CRUD_VIEW_TYPE.UPDATE} mutateFunc={mutateList} />
              </DrawerContainer>
            </>
          }
        />

        <Route path="/:clientId" element={<h1>project id </h1>} />
      </Routes>

      <div className="container">
        <PageHeader title="Clients">
          <Link to="add-user">
            <Button className="mt-3" type="primary">
              Add user
            </Button>
          </Link>
        </PageHeader>

        <div className="row" style={{}}>
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="col-12 d-flex align-items-center border-bottom bg-white"
                style={{ boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.05)' }}
              >
                <div className="ps-2 my-3 " style={{ width: '33%' }}>
                  <p className="p-mini opacity-50 ">Email</p>
                  <p className="mb-1 text-black  text-truncate">{item.email}</p>
                </div>

                <div className="ps-2 my-3 " style={{ width: '33%' }}>
                  <p className="p-mini opacity-50 ">Name</p>
                  <p className="mb-1 fw-bold text-black  text text-truncate">{item.name}</p>
                </div>

                {/*<div className="ps-2 my-3 " style={{ width: '30%', maxWidth: '200px' }}>
                  <p className="p-mini opacity-50 ">Active status</p>
                  <p className="mb-1 ms-4">{item.active === true ? <Badge color="green" /> : <Badge color="red" />}</p>
                </div>*/}

                <div className="d-flex justify-content-end " style={{ width: '33%' }}>
                  <Link to={'view/' + item._id} className="">
                    <Button>View</Button>
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
