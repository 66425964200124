/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30/07/2023.
 */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { CloseCircleFilled } from '@ant-design/icons'
import { Button, Empty, Popconfirm, Select } from 'antd'
import { useClients } from '../../hooks/useClients'
import { post } from '../../service/API'
import { API_URL } from '../../constants'
import { mutate as globalMutate } from 'swr'

type Props = { onClose?: () => void }

export const ProjectAttachClient = ({ onClose }: Props) => {
  const { projectId } = useParams() as { projectId: string }

  const [state, setState] = useState<{ clientId: string; projectId: string } | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  //const [selected, setSelected] = useState<boolean>(false)
  const { data, mutate } = useClients()

  const clientOnProject = data.filter((client) => {
    return client.projects.indexOf(projectId) > -1 ? true : false
  })

  const options = data
    .filter((client) => {
      return client.projects.indexOf(projectId) === -1 ? true : false
    })
    .map((item) => {
      return { value: item._id, label: item.name + ' - ' + item.email }
    })

  const onChange = (k, l) => {
    console.log('ProjectAttachClient > k, l = ', k, l)
    setState({ clientId: k, projectId: projectId })
  }

  const onSearch = () => {}

  const saveClick = () => {
    console.log('ProjectAttachClient > save = ')

    if (isLoading || state === null) {
      return
    }

    setLoading(true)

    post(API_URL + '/admin/clients/add', state)
      .then((result) => {
        console.log('ProjectAttachClient > result = ', result)
        mutate()

        globalMutate(API_URL + '/admin/clients/' + projectId)
        setState(null)
        setLoading(false)

        if (onClose) {
          onClose()
        }
        return result
      })
      .catch((error) => {
        console.log('ProjectAttachClient > error = ', error)
        setLoading(false)
        return Promise.reject(error)
      })
  }

  const removeClient = (clientId) => {
    const obj = { clientId: clientId, projectId: projectId }
    setLoading(true)
    post(API_URL + '/admin/clients/add', obj, 'DELETE')
      .then((result) => {
        console.log('ProjectAttachClient > result = ', result)
        mutate()
        globalMutate(API_URL + '/admin/clients/' + projectId)
        setLoading(false)
        return result
      })
      .catch((error) => {
        console.log('ProjectAttachClient > error = ', error)
        setLoading(false)
        return Promise.reject(error)
      })
  }

  return (
    <div className="container-fluid">
      <div className="row" style={{}}>
        <div className="col-12">
          <h5 className="mb-4">Add Access to project</h5>
        </div>
      </div>

      <div className="row" style={{}}>
        <div className="col-12">
          <Select
            className="w-100"
            size="middle"
            showSearch
            placeholder="Select a person"
            optionFilterProp="children"
            //loading={isLoading}
            onChange={onChange}
            onSearch={onSearch}
            //filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            filterOption={(input, option) => {
              //console.log('input', option)
              /*const str = option?.label ? option?.label.toString().indexOf(input) : null
              const res = str && str > -1 ? true : false
              console.log('res', res)
              return res*/

              if (option?.label) {
                const k = option?.label.toString().toLowerCase().indexOf(input) > -1 ? true : false
                console.log('k', k)
                return k
              } else {
                return true
              }
            }}
            options={options}
          />
        </div>
      </div>
      <div className="row" style={{}}>
        <div className="col-12 mt-2">
          <Button className="w-100 " size="large" type="primary" onClick={saveClick} loading={isLoading}>
            Save
          </Button>
        </div>
      </div>
      <div className="row" style={{}}>
        <div className="col-12 "></div>
      </div>
      <div className="row" style={{}}>
        <div className="col-12 mt-6">
          <div className="border rounded-3 ">
            {clientOnProject.length === 0 ? (
              <div className="py-5 pointer">
                <Empty description="No clients has access access"></Empty>
                <div className="d-flex justify-content-center mt-3"></div>
              </div>
            ) : (
              <>
                <div className="border-bottom py-3 d-flex justify-content-between align-items-center">
                  <p className="ms-3">The following clients has access :</p>
                </div>

                <div className="">
                  {clientOnProject.map((item, index) => {
                    return <SiteItem key={index} site={item} remove={removeClient}></SiteItem>
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

type SiteItemProps = { site: any; remove?: (id: string) => void }
const SiteItem = ({ site, remove }: SiteItemProps) => {
  const cancel = () => {}
  const confirm = () => {
    if (remove) {
      remove(site._id)
    }
  }

  return (
    <div className="d-flex justify-content-between py-3 border-bottom">
      <div className="ps-3">
        <h6 className="mb-0">{site.email}</h6>
      </div>

      <div className="me-4">
        <Popconfirm
          placement="left"
          title="Remove client access"
          description="Are you sure?  "
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          {remove ? <CloseCircleFilled style={{ fontSize: '1.5rem' }} /> : null}
        </Popconfirm>
      </div>
    </div>
  )
}
