export const getWebhookConfig = () => {
  return webhookConfig
}

const webhookConfig = [
  {
    id: 'title',
    type: 'TEXT',
    placeholder: 'Add title',
    label: 'Title',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    disabled: true,
  },
  {
    id: 'url',
    type: 'TEXT',
    placeholder: 'URL',
    label: 'Webhook url',
    value: '',
    validation: { required: true },
    errorMsg: 'This field is required ',
    disabled: true,
  },
]
