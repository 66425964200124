/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/03/2023.
 */
import React from 'react'
import { Link, NavLink, Outlet } from 'react-router-dom'
import { Header } from './Header'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ROUTES } from '../../config/routes'
import { COLOR_PRIMARY } from '../../constants'
import { animated, useSpring } from '@react-spring/web'

//TODO: When you get props pass them, uncommented to avoid empty object pattern
//type Props = {}

const headerHeight = 57

export const Layout = () => {
  return (
    <div className="container-fluid h-100">
      <div className="row flex-nowrap">
        <div
          className="col-1 bg-white px-0 min-vh-100"
          style={{
            width: 175,
            borderRight: '1px solid rgba(0,0,0,.1)',
            boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.04)',
          }}
        >
          <Link
            to="/"
            className="border-bottom d-flex align-items-center text-decoration-none"
            style={{ height: headerHeight }}
          >
            <Logo className="ms-3" style={{ height: headerHeight - 30 }}></Logo>
            <h6 className="fw-bold ms-2 mb-0 text-black">LEAD SITE</h6>
          </Link>
          <div className="mt-4 d-flex flex-column align-items-center align-items-sm-start flex-grow-1  ">
            {ROUTES.map((item, index) => {
              return (
                <NavLink key={index} to={item.path} className="text-decoration-none w-100">
                  {({ isActive }) => {
                    return <NavItem active={isActive} item={item}></NavItem>
                  }}
                </NavLink>
              )
            })}
          </div>
        </div>

        {/*{projectId ? (
          <div
            className="col-1 bg-white px-0 min-vh-100"
            style={{
              width: 175,
              borderRight: '1px solid rgba(0,0,0,.1)',
              boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.04)',
            }}
          >
            <Link to="/" className="border-bottom d-flex align-items-center" style={{ height: headerHeight }}>
              <Logo className="ms-4"></Logo>
            </Link>
            <div className="mt-4 d-flex flex-column align-items-center align-items-sm-start flex-grow-1  ">
              {projectId &&
                ROUTES.map((item, index) => {
                  return (
                    <NavLink key={index} to={item.gotoPage(projectId)} className="text-decoration-none w-100">
                      {({ isActive }) => {
                        return <NavItem active={isActive} item={item} projectId={projectId}></NavItem>
                      }}
                    </NavLink>
                  )
                })}
            </div>
          </div>
        ) : (
          <div
            className="col-1 bg-white px-0 border-bottom"
            style={{
              height: headerHeight,
              width: 175,
              borderRight: '1px solid rgba(0,0,0,.1)',
              boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.04)',
            }}
          >
            <Link to="/" className="d-flex align-items-center text-decoration-none" style={{ height: headerHeight }}>
              <Logo className="ms-3" style={{ height: headerHeight - 30 }}></Logo>
              <h6 className="fw-bold ms-2 mb-0 text-black">LEAD SITE</h6>
            </Link>
          </div>
        )}*/}

        <div className="col px-0 d-flex flex-column flex-grow-1 ">
          <Header headerHeight={headerHeight}></Header>
          {/*<Header headerHeight={headerHeight}></Header>
          <HeaderBlock></HeaderBlock>*/}

          <Outlet />
        </div>
      </div>
    </div>
  )
}

const NavItem = ({ item, active }) => {
  const blockWidth = 4

  const activeStyles = {
    opacity: 0,
    color: COLOR_PRIMARY,
    transform: `translate3d(0px,${10}px,0)`,
    config: { tension: 450, friction: 32 },
  }
  const inActiveStyles = {
    opacity: 0.8,
    color: 'rgba(0,0,0,.5)',

    transform: `translate3d(0px,${0}px,0)`,
    config: { tension: 450, friction: 32 },
  }

  const aniProps = useSpring(active ? activeStyles : inActiveStyles)
  const aniPropsOn = useSpring({
    opacity: active ? 1 : 0,
    transform: active ? `translate3d(0px,${0}px,0)` : `translate3d(0px,${-20}px,0)`,
    config: { tension: 450, friction: 32 },
  })

  const blockAniProps = useSpring({
    transform: active ? `translate3d(${0}px,0,0)` : `translate3d(${-blockWidth}px,0,0)`,
  })

  return (
    <div className="w-100 position-relative py-1">
      <animated.p
        className="position-absolute  fw-bold h-100 bg-primary"
        style={{ top: 0, width: blockWidth, ...blockAniProps }}
      ></animated.p>

      <div className="overflow-hidden position-relative" style={{ height: 40 }}>
        <animated.p className="ms-4 my-2 fw-bold position-absolute " style={{ fontSize: '.9rem', ...aniPropsOn }}>
          {item.title}
        </animated.p>
        <animated.p className="ms-4 my-2 fw-bold position-absolute" style={{ fontSize: '.9rem', ...aniProps }}>
          {item.title}
        </animated.p>
      </div>
    </div>
  )
}
