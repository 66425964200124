/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04/05/2022.
 */
import React from 'react'

type Props = {
  label: string
  children?: React.ReactElement[] | React.ReactElement | null
  labelClass?: string
}

export const FormLabel = ({ label, children = null, labelClass = 'mb-1 p-small' }: Props) => {
  return <div className="opacity-75">{children ? children : <p className={labelClass}>{label}</p>}</div>
}
