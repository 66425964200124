import { ColorPicker, theme } from 'antd'
import { useEffect, useState } from 'react'
import { FormLabel } from './FormLabel'
import { CRUD_VIEW_TYPE } from '../../Types'
import { FormDataType } from './FormTypes'
import { UseFormReturn } from 'react-hook-form'
import { Color } from 'antd/lib/color-picker'

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/04/2022.
 */

type Props = {
  data: FormDataType
  formHook: UseFormReturn
  viewType: CRUD_VIEW_TYPE
}

export const FormColorPicker = ({ data, formHook, viewType }: Props) => {
  const className = data.className || 'col-12 w-100 '

  const {
    register,
    setValue,
    unregister,
    formState: { errors },
  } = formHook

  const errorState = errors[data.id]
  const errorMessage: string =
    errorState && errorState.message ? (errorState.message as string) : (data.errorMsg as string)

  const { token } = theme.useToken()
  //const { token } = theme.useToken()
  const defaultColor: string | Color = data.value ? (data.value as string) : token.colorPrimary

  const [color, setColor] = useState<Color | string>(defaultColor)

  useEffect(() => {
    register(data.id, data.validation)

    return () => {
      unregister(data.id)
    }
  }, [register, unregister, data.id, data.validation])

  useEffect(() => {
    if (data.value) {
      setValue(data.id, data.value)
    }
  }, [data.value, data.id, data.validation, register, setValue])

  const onChange = (value, w) => {
    const k = value.toHex()
    console.log('onChange > k = ', k)
    console.log('FormColorPicker > w = ', w)
    setColor(k)
    setValue(data.id, w)
  }

  //const currentValue = getValues(data.id) ? getValues(data.id) : data.value
  //const maybeDisabled = data.disabled ? data.disabled[viewType] : false

  return (
    <div className={` ${className} py-2 `}>
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>
      <ColorPicker
        size="large"
        value={color}
        onChange={onChange}
        showText={(color) => <span>{color.toHexString()}</span>}
      />
      {/*<Input
        placeholder={data.placeholder}
        onChange={onChange}
        defaultValue={currentValue}
        status={errorState ? 'error' : ''}
        disabled={currentValue && maybeDisabled ? true : false}
      />*/}
      {errorMessage ? <div className="p-small text-danger">{errorMessage}</div> : null}
    </div>
  )
}
