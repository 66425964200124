/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 01/06/2023.
 */
import notification from 'antd/lib/notification'
import { SmileOutlined } from '@ant-design/icons'

export const openNotification = (
  msg: string = 'Updated',
  description: string = 'add a description',
  smiley: 'happy' | 'sad' | '' = ''
) => {
  notification.open({
    duration: 4,
    message: msg,
    description: description,
    icon: smiley === 'happy' ? <SmileOutlined style={{ color: '#108ee9' }} /> : null,
  })
}
